import React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { navigate } from 'gatsby'
import { colors, breakpoints } from '../../styles/variables'

const imageSrc = '/images/arrow-card.png'

const CardContainer = styled.div`
  width: 200px;
  min-width: 200px;
  height: 319px;
  color: ${colors.black};
  margin: 10px 10px 0px 0px;
  background: url(${imageSrc});
  background-repeat: no-repeat;
  background-size: 200px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 145px;
    min-width: 145px;
    height: 247px;
    background-size: 150px;
  }
`

const CardPictureContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: none;
  width: 100%;
  height: 250px;
  border: none;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    height: 145px;
  }
`

const Picture = styled.div`
  position: absolute;
  width: 200px;
  height: 200px;
  background: none;
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 145px;
    height: 145px;
  }
`

const Text = styled.div`
  position: relative;
  font-family: Montserrat;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: ${colors.white};
  margin-bottom: 65px;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 11px;
    line-height: 13px;
    margin-bottom: 10px;
  }
`

interface Props {
  url: string
}

const ArrowCard: React.FC<Props> = observer((props) => {
  const navigateToListPage = () => {
    console.log('Navigation to list page', props)
    navigate(props.url.includes('podcastit') ? '/podcastit' : `/listasivu/${props.url}`)
  }

  return (
    <CardContainer>
      <CardPictureContainer onClick={navigateToListPage}>
        <Picture />
        <Text>Lisää teemasta</Text>
      </CardPictureContainer>
    </CardContainer>
  )
})

export { ArrowCard }
