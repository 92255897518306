import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react-lite'
import styled from 'styled-components'
import { PlayArrow, Pause } from '@material-ui/icons'
import { useMst } from '../../models/Root'
import { useMediaQuery } from 'react-responsive'
import { breakpoints, colors } from '../../styles/variables'
import { RADIO_CHANNEL_MEDIA_ID } from '../Category/utils'

const Play = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 100px;
  cursor: pointer;
  transition: 250ms all;

  :hover {
    background-color: ${colors.white};
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 38px;
    height: 38px;
  }
`

const styles = {
  buttonSmall: {
    fontSize: 23,
    color: colors.black
  },
  buttonLarge: {
    fontSize: 47,
    color: colors.black
  }
}

interface Props {
  mediaId: number
  mediaSrc: string
  showName: string
  title: string
  duration?: number
  progress?: number
  live?: boolean
  marginLeft?: string
  mediaSeriesId?: number
}

const CardPlayButton: React.FC<Props> = observer((props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl })

  const { mediaPlayer } = useMst()
  const { mediaId } = props

  const [playing, setPlaying] = useState(mediaPlayer.mediaId === props.mediaId)

  useEffect(() => {
    setPlaying(mediaPlayer.mediaId === props.mediaId)
  }, [mediaPlayer.mediaId])

  useEffect(() => {
    if (mediaPlayer.paused) {
      setPlaying(false)
    } else if (mediaPlayer.mediaId === props.mediaId) {
      setPlaying(true)
    }
  }, [mediaPlayer.paused])

  const playClicked = () => {
    console.log('PLAY FOR MEDIA ID', mediaId, 'CLICKED', mediaPlayer.isPlaying, mediaPlayer.paused,)

    if (props.live) {
      console.log('WE ARE LIVE, DOING THINGS')
      if (mediaPlayer.mediaId === props.mediaId) {
        if (mediaPlayer.paused) {
          console.log('WE ARE ALREADY PAUSED, SHOULD CONTINUE')
          mediaPlayer.requestResume(true)
          mediaPlayer.setPause(false)
          setPlaying(true)
        } else {
          console.log('PAUSE CALLBACKED FROM PLAYCLICK')
          mediaPlayer.setPause(true)
          setPlaying(false)
        }
      } else {
        console.log('LIVE RADIO BUTTON CLICKED')
        mediaPlayer.switchStream(mediaId - RADIO_CHANNEL_MEDIA_ID)
        setPlaying(true)
        mediaPlayer.setMediaId(props.mediaId)
        mediaPlayer.setPause(false)
      }
      mediaPlayer.setLive(true)
    } else {
      if (mediaPlayer.mediaId === props.mediaId) {
        console.log('WE GOT PRESSED THE SAME MEDIA, PAUSE OR RESUME DEPENDING ON STATUS', mediaPlayer.paused)
        if (mediaPlayer.paused) {
          console.log('WE ARE ALREADY PAUSED, SHOULD CONTINUE')
          mediaPlayer.requestResume(true)
          mediaPlayer.setPause(false)
          setPlaying(true)
        } else {
          console.log('PAUSE CALLBACKED FROM PLAYCLICK')
          mediaPlayer.setPause(true)
          setPlaying(false)
        }
      } else {
        console.log('WE GOT PRESSED A NEW MEDIA, LETS PLAY IT')
        mediaPlayer.setStreamURL(props.mediaSrc)
        mediaPlayer.setShowName(props.showName)
        mediaPlayer.setEpisodeName(props.title)
        mediaPlayer.setMediaId(props.mediaId)

        const audioElement = document.querySelector('audio');
        console.log('audio element', audioElement)

        // if (audioElement) {
        //   audioElement.src = props.mediaSrc;
        //   audioElement.oncanplay = () => {
        //     console.log('Can play, not through, overwritten');
        //     audioElement.muted = false;
        //     audioElement.play();
        //   }
        //   audioElement.oncanplaythrough = () => {
        //     console.log('Can play through, overwritten');
        //     audioElement.muted = false;
        //     audioElement.play();
        //   }
        //   audioElement.muted = false;
        //   audioElement.play();

        // }


        if (props.mediaSeriesId) mediaPlayer.setMediaSeriesId(props.mediaSeriesId)
        if (props.duration) mediaPlayer.setMediaDuration(props.duration)
        if (!mediaPlayer.isPlaying) {
          console.log('MEDIA PLAYER AINT PLAYING, WE GOTS TO START IT')
          mediaPlayer.togglePlay()
          mediaPlayer.setPause(false)
        }
        if (props.progress) {
          console.log('WE HAVE EXISTING PROGRESS, NEED TO INFORM THE PLAYER ABOUT THIS VIA STORE')
          mediaPlayer.setProgress(props.progress)
        }
        console.log('OUR PLAYING STATUS: ', playing)
      }
      mediaPlayer.setLive(false)
    }
  }

  if (!playing)
    return (
      <Play>
        <PlayArrow style={isMobile ? styles.buttonSmall : styles.buttonLarge} onClickCapture={playClicked} />
      </Play>
    )
  else
    return (
      <Play>
        <Pause style={isMobile ? styles.buttonSmall : styles.buttonLarge} onClickCapture={playClicked} />
      </Play>
    )
})
export { CardPlayButton }
