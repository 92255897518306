import React, { MouseEvent, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useMediaQuery } from 'react-responsive'
import { Link, navigate } from 'gatsby'
import { useMst } from '../../models/Root'
// @ts-ignore
import PlayIcon from '../../resources/icons/playbutton.svg'
// @ts-ignore
import LinkArrowIcon from '../../resources/icons/linkarrow.svg'
// @ts-ignore
import FishIcon from '../../resources/icons/fish-icon.svg'
// @ts-ignore
import Delete from '../../resources/icons/delete-icon.svg'
// @ts-ignore
import Video from '../../resources/icons/video-icon.svg'
import VideoPlayer from '../VideoPlayer'
import { CardPlayButton } from '../CardPlayButton'
import { breakpoints, colors, fonts } from '../../styles/variables'
import './me.css'

const CardContainer = styled.div`
  width: 200px;
  min-width: 200px;
  height: 300px;
  color: ${colors.black};
  margin: 10px 10px 10px 0px;
  word-wrap: break-word;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 145px;
    min-width: 145px;
    height: 230px;
    background-size: 150px;
  }
`

const CardPictureContainer = styled.div.attrs((props: { imageSrc: string }) => props)`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: none;
  width: 100%;
  height: 200px;
  background: url(${(props) => `"${props.imageSrc}"`});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    height: 145px;
  }
`

const Picture = styled.button.attrs((props: { mediaType: string }) => props)`
  position: absolute;
  display: flex;
  width: 200px;
  height: 200px;
  max-width: 200px;
  max-height: 200px;
  background: none;
  border: none;
  cursor: ${(props) => props.mediaType === 'video' ? 'auto' : 'pointer'};
  justify-content: center;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 145px;
    height: 145px;
    max-width: 145px;
    max-height: 145px;
  }
`

const DeleteButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 10;
  padding: 0px;
  cursor: pointer;
  background: none;
  border: none;
`

const VideoButton = styled.button`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;
  z-index: 10;
  background-color: rgba(255, 255, 255, 0.6);
  padding: 0px;
  width: 78px;
  height: 78px;
  border-radius: 100px;
  cursor: pointer;
  transition: 250ms all;
  border: none;

  :hover {
    background-color: ${colors.white};
  }

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    width: 38px;
    height: 38px;
  }
`

const BottomContainer = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
  flex: 1;
  background: none;
  border: none;
  cursor: pointer;

  :disabled {
    color: ${colors.black};
  }
`

const ShowNameAndTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 65px;
  margin-top: 8px;
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    height: 50px;
    margin-top: 3px;
    color: ${colors.black};
  }
`

const ShowName = styled.div.attrs((props: { mediaType: string }) => props)`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: ${(props) => props.mediaType === 'video' ? 'auto' : 'pointer'};
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 11px;
    line-height: 13px;
    color: ${colors.black};
  }
`

const EpisodeTitle = styled.div.attrs((props: { mediaType: string }) => props)`
  font-family: ${fonts.codeProMono};
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 20px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
  cursor: ${(props) => props.mediaType === 'video' ? 'auto' : 'pointer'};
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 11px;
    line-height: 13px;
    width: 125px;
    height: 25px;
    color: ${colors.black};
  }
`

const MoreEpisodesContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-self: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 8px;
  flex: 1 0 0;
`

const MoreEpisodes = styled.div`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: underline;
  color: ${colors.deiRed};
  cursor: pointer;

  @media ${`(max-width: ${breakpoints.xl}px)`} {
    font-size: 12px;
    line-height: 16px;
  }
`

const styles = {
  deleteIconSmall: {
    width: 28,
    height: 28,
  },
  deleteIconLarge: {
    width: 40,
    height: 40,
  },
  videoIconSmall: {
    width: 23,
    height: 23,
  },
  videoIconLarge: {
    width: 40,
    height: 40,
  }
}

// @ts-ignore
interface Props {
  id: number
  title: string
  showName: string
  showLikeButton: boolean
  duration?: number
  pictureSrc?: string
  seriesLink: boolean
  mediaSrc: string
  mediaSeriesId?: number
  progress?: number
  radioChannel?: boolean
  radioChannelName?: string
  radioChannelUrl?: string
  isInProgress?: boolean
  mediaType: string
  videoUrl?: string
  videoSeries?: boolean,
  videoType?: string
}

const MediaElement: React.FC<Props> = observer((props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.xl })
  const [videoOpen, setVideoOpen] = useState(false)
  const { progress, mediaPlayer } = useMst()
  const imageSrc = props.pictureSrc ? props.pictureSrc.replace(' ', '%20') : props.seriesLink ?
    '/images/dei_plus_card_placeholder_series.png' : '/images/dei_plus_card_placeholder_files.png'

  const navigateToMediaSeries = () => {
    if (props.seriesLink) navigate(`/ohjelmasarja/${props.id}?mediaType=${props.mediaType}`)
    else navigate(`/ohjelmasarja/${props.mediaSeriesId}?mediaType=${props.mediaType}`)
  }

  const handleDeletePress = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation()
    e.preventDefault()
    progress.removeFromInProgress(props.id)
  }

  const playVideo = () => {
    mediaPlayer.setPause(true)
    setVideoOpen(!videoOpen)
  }

  return (
    <CardContainer>
      <CardPictureContainer imageSrc={imageSrc}>

        <Picture disabled={props.mediaType === 'video'} onClick={navigateToMediaSeries} mediaType={props.mediaType} />
        {props.isInProgress && (
          <DeleteButton onClick={handleDeletePress} >
            <Delete fill={colors.deiRed} style={isMobile ? styles.deleteIconSmall : styles.deleteIconLarge} />
          </DeleteButton>
        )}
        {!props.seriesLink && props.mediaType !== 'video' && (
          <CardPlayButton
            mediaSrc={
              decodeURIComponent(props.mediaSrc).includes('http') ? props.mediaSrc : 'https://jako.dei.fi/ondemand/lowbit/' + props.mediaSrc
            }
            showName={props.showName}
            title={props.title}
            mediaId={props.id}
            progress={props.progress}
            duration={props.duration}
            live={props.radioChannel}
            mediaSeriesId={props.mediaSeriesId}
          />
        )}
        {props.mediaType === 'video' && !props.videoSeries && (
          <>
            <VideoButton onClick={playVideo}>
              <Video fill={colors.black} style={isMobile ? styles.videoIconSmall : styles.videoIconLarge} />
            </VideoButton>
            <VideoPlayer url={props.videoUrl!} videoOpen={videoOpen} setVideoOpen={(value) => setVideoOpen(value)} videoType={props.videoType} />
            {/* <div dangerouslySetInnerHTML={{ __html: props.videoUrl ?? '' }} /> */}
          </>
        )}
      </CardPictureContainer>
      <BottomContainer disabled={!(props.mediaType !== 'video' || props.seriesLink)} onClick={navigateToMediaSeries}>
        <ShowNameAndTitleContainer>
          <ShowName mediaType={props.mediaType} >{props.showName}</ShowName>
          <EpisodeTitle mediaType={props.mediaType}>{props.title}</EpisodeTitle>
        </ShowNameAndTitleContainer>
        {(props.mediaType !== 'video' || props.seriesLink) && (
          <MoreEpisodesContainer>
            <MoreEpisodes>Lisää jaksoja</MoreEpisodes>
          </MoreEpisodesContainer>
        )}
      </BottomContainer>
    </CardContainer>
  )
})

export { MediaElement }
