import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react-lite'
import { useMediaQuery } from 'react-responsive'
import './scrolling-menu.css'
import { MediaElement } from '../MediaElement'
import { RadioChannelMediaElement } from '../MediaElement/RadioChannelMediaElement'
import { ArrowCard } from '../MediaElement/ArrowCard'
import { useMst } from '../../models/Root'
import {
  getLatestMediaSeries,
  getCategorySeries,
  getCaterofyFiles,
  getRecommendedData,
  getArchiveData,
  getPodcastSeries,
  getPodcastFiles
} from '../../services/api'
import { breakpoints, colors, fonts } from '../../styles/variables'
import { RADIO_CHANNEL_MEDIA_ID } from './utils'

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
`

const EmptyCategoryContainer = styled.div`
  height: 70px;
  width: 100%;
`

const CategoryElement = styled.div.attrs((props: { categoryIndex: number; padding: string }) => props)`
  width: 100vw;
  padding-top: ${(props) => props.padding || '100'}px;
  padding-bottom: ${(props) => props.padding || '120'}px;
  background: ${(props) => (props.categoryIndex % 2 ? `${colors.gray.ligh}` : `${colors.white}`)};

  @media ${`(max-width: ${breakpoints.md}px)`} {
    display: flex;
    flex-direction: column;
    align-content: flex-start;
    overflow-x: scroll;
    scroll-behavior: smooth;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
    padding-left: 15px;
    padding-right: 0px;
    padding-top: 30px;
  }
`

const GategoryGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(5, minmax(200px, auto));
  column-gap: 20px;
  justify-content: center;
  align-content: center;
  width: 100%;

  @media (max-width: 1280px) {
    grid-template-columns: repeat(5, minmax(150px, auto));
    column-gap: 2%;
  }

  @media ${`(max-width: ${breakpoints.md}px)`} {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: flex-start;
    width: 100%;
    margin-left: 0;
  }
`

const CategoryNameContainer = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  margin: auto;

  @media ${`(max-width: ${breakpoints.md}px)`} {
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-content: flex-start;
  }
`
const CategoryName = styled.h1`
  font-family: ${fonts.montserrat};
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 39px;
  color: ${colors.black};

  @media ${`(max-width: ${breakpoints.md}px)`} {
    font-size: 20px;
    line-height: 24px;
  }
`

// @ts-ignore
interface Props {
  title: string
  description: string
  type: string
  listPage?: boolean
  prefilledData?: any[]
  categoryIndex?: number
  screenTitle?: string
  padding?: string
}

const Category: React.FC<Props> = observer((props) => {
  const isMobile = useMediaQuery({ maxWidth: breakpoints.md })
  const { title, type, screenTitle } = props
  const { mediaPlayer, favorites, progress } = useMst()

  let menuRef = useRef(null)
  const [apiResult, setApiResult] = useState([])
  const [apiFetched, setApiFetched] = useState(false)
  const [apiHasMoreResults, setApiHasMoreResults] = useState(true)
  const [apiPage, setApiPage] = useState(1)

  const fetchData = async () => {
    if (!apiFetched && apiResult.length === 0) {
      setApiFetched(true)
      switch (type) {
        case 'latest':
        case 'list-latest':
          getLatestMediaSeries(1, type === 'latest' ? 10 : 5).then((res) => {
            // console.log(res.data, 'latest problems')
            setApiResult(res.data)
          })
          break
        case 'category':
        case 'list-category-files':
          getCaterofyFiles(title, 1, type === 'category' ? 4 : 5).then((res) => {
            setApiResult(res.data)
          })
          break
        case 'list-category-series':
          getCategorySeries(title, apiPage, 10).then((res) => {
            setApiResult(res.data)
            setApiPage(apiPage + 1)
          })
          setApiFetched(true)
          break
        case 'recommended':
        case 'list-recommended':
          getRecommendedData().then((res) => {
            setApiResult(res.data)
          })
          break
        case 'list-archive':
          getArchiveData(1, 5).then((res) => {
            setApiResult(res.data)
          })
          break
        case 'list-searchresult':
          if (props.prefilledData) {
            // console.log('Yees yahas, we has a search result thing :D');
            // @ts-ignore
            setApiResult(props.prefilledData)
          }
          break
        case 'podcast':
        case 'list-podcast-files':
          getPodcastFiles(0, type === 'podcast' ? 4 : 5).then((data) => {
            // @ts-ignore
            setApiResult(data.data)
          })
          break
        case 'list-podcast-series':
          getPodcastSeries(apiPage, 10).then((res) => {
            setApiResult(res.data)
            setApiPage(apiPage + 1)
          })
          setApiFetched(true)
          break
        case 'list-radiochannels-live':
          // console.log('LAIV CHANELS')
          const radioChannels = []
          for (let i = 0; i < 12; i++) {
            radioChannels.push({
              name: mediaPlayer.getStreamNameFromIndex(i),
              url: mediaPlayer.getStreamUrlFromIndex(i),
              mediaId: RADIO_CHANNEL_MEDIA_ID + i,
              mediaIndex: i
            })
          }

          // @ts-expect-error
          setApiResult(radioChannels)

          break

        case 'list-progress':
          if (progress.localProgress.length && progress.progressEntries.length === 0) {
            await progress.initializeProgress()
          }
          // @ts-ignore
          setApiResult(progress.progressEntries)

          break
        case 'list-favorites-series':
          if (favorites.localFavorites.length && favorites.favorites.length === 0) {
            // console.log('Favorites not properly initialized? ');
            await favorites.initializeFavorites()
          }

          if (favorites.favorites.length) {
            const series = favorites.favorites.slice().filter((item) => item.seriesLink)
            // @ts-ignore
            setApiResult(series)
          }
          break
        case 'list-favorites-files':
          if (favorites.favorites.length) {
            const notSeries = favorites.favorites.slice().filter((item) => !item.seriesLink)
            // @ts-ignore
            setApiResult(notSeries)
          }
          break
      }
    }
  }

  useEffect(() => {
    if ((type === 'list-category-series' || type === 'list-podcast-series') && apiHasMoreResults) {
      fetchMoreData()
    }
  }, [apiPage])

  const fetchMoreData = () => {
    if (!apiFetched) {
      return
    }
    if (type === 'list-category-series') {
      getCategorySeries(title, apiPage, 10).then((data) => {
        if (data.data?.length === 0) {
          setApiHasMoreResults(false)
          return
        }
        // @ts-ignore
        const newFiles = [...apiResult, ...data.data]

        // @ts-ignore
        setApiResult(newFiles)
        setApiPage(apiPage + 1)
      })
    }

    if (type === 'list-podcast-series') {
      getPodcastSeries(apiPage, 10).then((data) => {
        if (data.data?.length === 0) {
          setApiHasMoreResults(false)
          return
        }
        // @ts-ignore
        const newFiles = [...apiResult, ...data.data]

        // @ts-ignore
        setApiResult(newFiles)
        setApiPage(apiPage + 1)
      })
    }
  }

  useEffect(() => {
    fetchData()
  }, [type])

  const Menu = (
    list: {
      title?: string
      showName: string
      id: number
      showLikeButton: boolean
      duration?: number
      pictureSrc?: string
      seriesLink?: boolean
      mediaSrc?: string
      mediaSeriesId?: number
      progress?: number
      name?: string
      url?: string
      mediaId?: number
      thumbnailUrl?: string
      originalUrl?: string
    }[]
  ) =>
    list.map((el) => {
      const {
        title,
        showName,
        showLikeButton,
        duration,
        pictureSrc,
        seriesLink,
        mediaSrc,
        id,
        mediaSeriesId,
        progress,
        name,
        url,
        mediaId,
        mediaIndex,
        thumbnailUrl,
        originalUrl,
        videoType
      } = el

      console.log(el);

      const elementId = el.type === 'video' ? mediaSeriesId || id : id
      const imageSrc = el.type === 'video' ? thumbnailUrl : pictureSrc
      const mediaUri = el.type === 'video' ? originalUrl : mediaSrc
      if (type === 'list-radiochannels-live') {
        // console.log('live channels trickery list');
        return (
          <RadioChannelMediaElement
            radioChannel={true}
            radioChannelName={name}
            radioChannelUrl={url}
            id={mediaId ?? -1}
            key={mediaIndex + '-' + name}
            title={name ?? ''}
            mediaSrc={url ?? ''}
            mediaIndex={mediaIndex}
          />
        )
      } else {
        // if (videoType === "iframe") {

        // } else
        console.log('MEDIA ELEMENTTII ;:D', el.videoSeries)
        return (
          <MediaElement
            id={elementId}
            key={id + '-' + title + '-' + showName}
            title={title || ''}
            showName={showName}
            duration={duration}
            showLikeButton={showLikeButton}
            pictureSrc={imageSrc}
            seriesLink={seriesLink || false}
            mediaSrc={mediaUri || ''}
            mediaSeriesId={mediaSeriesId}
            progress={progress}
            isInProgress={type.includes('progress')}
            mediaType={el.videoSeries ? 'video' : el.type}
            videoUrl={originalUrl}
            videoSeries={el.videoSeries}
            videoType={videoType}
          />
        )
      }
    })

  console.log('APIRESULT', apiResult);
  const menu = Menu(apiResult)

  let categoryUrl = ''

  if (!type.includes('list-')) {
    switch (type) {
      case 'latest':
        categoryUrl = 'uusimmat'
        break
      case 'favorites':
        categoryUrl = 'suosikit'
        break
      case 'recommended':
        categoryUrl = 'suosituimmat'
        break
      case 'progress':
        categoryUrl = 'jatkakuuntelua'
        break
      case 'podcast':
        categoryUrl = 'podcastit'
        break
      default: {
        categoryUrl = encodeURIComponent(title)
        break
      }
    }
  }

  if (type !== 'latest' && type !== 'recommended' && !type.includes('list-') && apiResult.length > 0) {
    menu.push(<ArrowCard url={categoryUrl} />)
  }

  const categoryTitle = screenTitle?.length > 0 ? screenTitle : title.length > 0 ? title : ''

  const renderCategoryTitle = () => {
    return (
      <CategoryNameContainer>
        <CategoryName>{categoryTitle}</CategoryName>
      </CategoryNameContainer>
    )
  }

  return apiResult.length > 0 ? (
    <CategoryContainer>
      <CategoryElement categoryIndex={props.categoryIndex} padding={props.padding}>
        {isMobile && renderCategoryTitle()}
        <GategoryGrid ref={menuRef}>
          {!isMobile && renderCategoryTitle()}
          {menu}
        </GategoryGrid>
      </CategoryElement>
    </CategoryContainer>
  ) : <EmptyCategoryContainer />
})

export { Category }
